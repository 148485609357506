import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Stack } from "@mui/material";
import { AclNamespaces, AclPermissions, AclRuleStar, CreateAclsRequestBody, FeatureFlags, GroupDto, UserDto } from "api-shared";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";

import { useClientHasFeature } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds">;

type MethodFileUploadDialogProps = {
    open: boolean;
    onClose: () => void;
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

export const MethodFileUploadDialog = ({
    open = false,
    onClose = () => {},
    users,
    groups,
    onPermissionSubmit,
}: MethodFileUploadDialogProps) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: zodResolver(zFormUserGroupSchema),
        defaultValues: { userIds: [], groupIds: [] },
    });

    const onSubmit = handleSubmit((data) => {
        onPermissionSubmit({
            groupIds: data.groupIds,
            userIds: data.userIds,
            acl: {
                namespace: AclNamespaces.Method,
                permission: AclPermissions.FileUpload,
                rule: AclRuleStar,
                simpleEntityId: null,
            },
        });
        onClose();
    });

    const onCloseDialog = () => {
        onClose();
        reset();
    };

    const hasMethodSectionFeature = useClientHasFeature(FeatureFlags.FEATURE_METHOD_SECTION);
    if (!hasMethodSectionFeature) {
        return null;
    }

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_METHOD_FILE_UPLOAD)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCloseDialog}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack spacing={1}>
                    <Alert severity="info">{t(translationKeys.VDLANG_ACL_HINT_METHOD_FILE_UPLOAD)}</Alert>

                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
