import KeyboardArrowDownRounded from "@mui/icons-material/KeyboardArrowDownRounded";
import { Button, Menu, MenuItem } from "@mui/material";
import {
    AdminMeasureConfigListDto,
    AdminMeasureListDto,
    CreateAclsRequestBody,
    FeatureFlags,
    GroupDto,
    PlainDashboardDto,
    UserDto,
    UserStatus,
} from "api-shared";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useCreatePermissions } from "../../../domain/admin/permissions";
import { useClientHasFeature } from "../../../domain/client";
import useDialog from "../../../hooks/useDialog";
import useMenu from "../../../hooks/useMenu";
import { translationKeys } from "../../../translations/main-translations";
import { AccessPermissionDialog } from "./AccessPermissionDialog";
import { APIPermissionDialog } from "./APIPermissionDialog";
import { CanCompleteEffectivenessDialog } from "./CanCompleteEffectivenessDialog";
import { DashboardDialog } from "./DashboardDialog";
import { MeasureCreationDialog } from "./MeasureCreationDialog";
import { MeasureDialog } from "./MeasureDialog";
import { MeasurePermissionDialog } from "./MeasurePermissionDialog";
import { MeasureStatusDialog } from "./MeasureStatusDialog";
import { MethodFileUploadDialog } from "./MethodFileUploadDialog";
import { PersonFilterDialog } from "./PersonFilterDialog";
import { SupplierCreationDialog } from "./SupplierCreationDialog";
import { ValuestreamDialog } from "./ValuestreamDialog";

type MenuChoice =
    | "valuestream"
    | "apiPermission"
    | "dashboard"
    | "supplierCreation"
    | "measure"
    | "measureCreation"
    | "measurePermission"
    | "measureStatusVisibility"
    | "accessPermission"
    | "personFilter"
    | "canCompleteEffectiveness"
    | "methodFileUpload";
export const MAX_OPTIONS_COUNT_PERMISSION_DIALOGS = 100;

interface AddRulesHandlingProps {
    users: UserDto[];
    groups: GroupDto[];
    measureConfigs?: AdminMeasureConfigListDto;
    dashboards?: PlainDashboardDto[];
    measures?: AdminMeasureListDto;
}

const options: { key: string; value: MenuChoice }[] = [
    { key: translationKeys.VDLANG_ACL_TITLE_ACCESS_PERMISSION, value: "accessPermission" },
    { key: translationKeys.VDLANG_ACL_TITLE_API_PERMISSION, value: "apiPermission" },
    { key: translationKeys.VDLANG_ACL_TITLE_DASHBOARD, value: "dashboard" },
    { key: translationKeys.VDLANG_ACL_TITLE_METHOD_FILE_UPLOAD, value: "methodFileUpload" },
    { key: translationKeys.VDLANG_ACL_TITLE_SUPPLIER_CREATION, value: "supplierCreation" },
    { key: translationKeys.VDLANG_ACL_TITLE_PERSON_FILTER, value: "personFilter" },
    { key: translationKeys.VDLANG_ACL_TITLE_PROCESS, value: "measure" },
    { key: translationKeys.VDLANG_ACL_TITLE_PROCESS_CREATION, value: "measureCreation" },
    { key: translationKeys.VDLANG_ACL_TITLE_MEASURE_PERMISSION, value: "measurePermission" },
    { key: translationKeys.VDLANG_ACL_TITLE_PROCESS_STATUS_VISBILITY, value: "measureStatusVisibility" },
    { key: translationKeys.VDLANG_ACL_TITLE_CAN_COMPLETE_EFFECTIVENESS, value: "canCompleteEffectiveness" },
    { key: translationKeys.VDLANG_ACL_TITLE_VALUESTREAM, value: "valuestream" },
];

const AddRulesHandling = ({ groups, users, measureConfigs, dashboards, measures }: AddRulesHandlingProps) => {
    const { t } = useTranslation();

    const valuestreamDialog = useDialog();
    const dashboardDialog = useDialog();
    const measureDialog = useDialog();
    const measureCreationDialog = useDialog();
    const measurePermissionDialog = useDialog();
    const supplierCreationDialog = useDialog();
    const measureStatusDialog = useDialog();
    const accessPermissionDialog = useDialog();
    const personFilterDialog = useDialog();
    const canCompleteEffectivenessDialog = useDialog();
    const methodFileUploadDialog = useDialog();
    const apiPermissionDialog = useDialog();

    const hasRestrictedEffectivenessCompletionFeature = useClientHasFeature(FeatureFlags.FEATURE_RESTRICTED_EFFECTIVENESS_COMPLETION);
    const hasMethodSectionFeature = useClientHasFeature(FeatureFlags.FEATURE_METHOD_SECTION);
    const removedOptions = hasRestrictedEffectivenessCompletionFeature ? [] : ["canCompleteEffectiveness"];
    if (!hasMethodSectionFeature) {
        removedOptions.push("methodFileUpload");
    }
    const filteredOptions = options.filter((option) => !removedOptions.includes(option.value));

    const menu = useMenu();
    // extract open function local variable, so that the linter is able to correctly track the dependencies of useCallback
    const openMenu = menu.open;
    const openRuleMenu = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            openMenu(event);
        },
        [openMenu],
    );

    const handleMenuItemClick = (choice: MenuChoice) => {
        if (choice === "valuestream") {
            valuestreamDialog.openDialog();
        }
        if (choice === "dashboard") {
            dashboardDialog.openDialog();
        }
        if (choice === "measure") {
            measureDialog.openDialog();
        }
        if (choice === "measureCreation") {
            measureCreationDialog.openDialog();
        }
        if (choice === "measurePermission") {
            measurePermissionDialog.openDialog();
        }
        if (choice === "supplierCreation") {
            supplierCreationDialog.openDialog();
        }
        if (choice === "measureStatusVisibility") {
            measureStatusDialog.openDialog();
        }
        if (choice === "accessPermission") {
            accessPermissionDialog.openDialog();
        }
        if (choice === "personFilter") {
            personFilterDialog.openDialog();
        }
        if (choice === "canCompleteEffectiveness" && hasRestrictedEffectivenessCompletionFeature) {
            canCompleteEffectivenessDialog.openDialog();
        }
        if (choice === "methodFileUpload" && hasMethodSectionFeature) {
            methodFileUploadDialog.openDialog();
        }
        if (choice === "apiPermission") {
            apiPermissionDialog.openDialog();
        }

        menu.closeMenu();
    };

    const validUsers = users.filter((u) => u.status !== UserStatus.STATUS_DELETED && u.status !== UserStatus.STATUS_INACTIVE);

    const createPermissionsMutation = useCreatePermissions();
    const handleCreateAclSubmit = (data: CreateAclsRequestBody) => createPermissionsMutation.mutate(data);

    return (
        <>
            <ValuestreamDialog
                open={valuestreamDialog.isOpen}
                onClose={valuestreamDialog.closeDialog}
                measureConfigs={measureConfigs ?? []}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <DashboardDialog
                open={dashboardDialog.isOpen}
                onClose={dashboardDialog.closeDialog}
                dashboards={dashboards ?? []}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <MeasureDialog
                open={measureDialog.isOpen}
                onClose={measureDialog.closeDialog}
                measures={measures ?? []}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <MeasureCreationDialog
                open={measureCreationDialog.isOpen}
                onClose={measureCreationDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <MeasurePermissionDialog
                open={measurePermissionDialog.isOpen}
                onClose={measurePermissionDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <SupplierCreationDialog
                open={supplierCreationDialog.isOpen}
                onClose={supplierCreationDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <MeasureStatusDialog
                open={measureStatusDialog.isOpen}
                onClose={measureStatusDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <AccessPermissionDialog
                open={accessPermissionDialog.isOpen}
                onClose={accessPermissionDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            <PersonFilterDialog
                open={personFilterDialog.isOpen}
                onClose={personFilterDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />
            {hasRestrictedEffectivenessCompletionFeature && (
                <CanCompleteEffectivenessDialog
                    open={canCompleteEffectivenessDialog.isOpen}
                    onClose={canCompleteEffectivenessDialog.closeDialog}
                    users={validUsers}
                    groups={groups}
                    onPermissionSubmit={handleCreateAclSubmit}
                />
            )}
            {hasMethodSectionFeature && (
                <MethodFileUploadDialog
                    open={methodFileUploadDialog.isOpen}
                    onClose={methodFileUploadDialog.closeDialog}
                    users={validUsers}
                    groups={groups}
                    onPermissionSubmit={handleCreateAclSubmit}
                />
            )}
            <APIPermissionDialog
                open={apiPermissionDialog.isOpen}
                onClose={apiPermissionDialog.closeDialog}
                users={validUsers}
                groups={groups}
                onPermissionSubmit={handleCreateAclSubmit}
            />

            <Button variant="contained" onClick={(e) => openRuleMenu(e)} endIcon={<KeyboardArrowDownRounded />}>
                {t(translationKeys.VDLANG_ACL_ADD_RULE)}
            </Button>

            <Menu {...menu.menuProps}>
                {filteredOptions.map((option) => (
                    <MenuItem key={option.key} selected={false} onClick={() => handleMenuItemClick(option.value)}>
                        {t(option.key)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default AddRulesHandling;
