import InsertPhotoIcon from "@mui/icons-material/InsertPhotoRounded";
import { Button, Card, CardActions, CardContent, CardHeader, FormGroup, Grid, InputLabel, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DangerButton from "../../../components/DangerButton";
import Form from "../../../components/Form";
import UploadInput from "../../../components/UploadInput";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import ValidatedInput from "../../../components/input/ValidatedInput";
import Select from "../../../components/input/select/Select";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import SingleUser from "../../../components/user/SingleUser";
import { useGroups } from "../../../domain/admin/groups";

import { GroupType, UserTier } from "api-shared";
import { useAdminDeleteClientLogo, useAdminUpdateClient, useAdminUpdateClientLogo } from "../../../domain/admin/client";
import { useAdminSuppliersQuery } from "../../../domain/admin/suppliers";
import { useAdminUsers } from "../../../domain/admin/users";
import { useCurrentClient } from "../../../domain/client";
import useDialog from "../../../hooks/useDialog";
import { baseUrl } from "../../../lib/api";
import { translationKeys } from "../../../translations/main-translations";
import { getUserTierOption, getUserTierOptions } from "../members/user-utils";
import AdminDashboardColorConfiguration from "./AdminDashboardColorConfiguration";
import ClientGroupSelect from "./ClientGroupSelect";

const LogoImg = styled("img")({
    maxWidth: "100%",
    maxHeight: 256,
});

const DefaultLogo = styled(InsertPhotoIcon)({
    width: "100%",
    height: 256,
});

const LogoUploadInput = styled(UploadInput)({ height: "100%" });

const validateClientName = (name: unknown): name is string =>
    typeof name === "string" && name.trim().length > 0 && name.trim().length < 255;

const TeamSetting = () => {
    const updateCurrentClientMutation = useAdminUpdateClient();
    const updateCurrentClientLogoMutation = useAdminUpdateClientLogo();
    const deleteCurrentClientLogoMutation = useAdminDeleteClientLogo();

    const updateClient = updateCurrentClientMutation.mutate;
    const updateClientLogo = updateCurrentClientLogoMutation.mutate;

    const deleteLogo = deleteCurrentClientLogoMutation.mutate;
    const groupsQuery = useGroups();

    const { t: translate } = useTranslation();
    const suppliersQuery = useAdminSuppliersQuery();
    const usersQuery = useAdminUsers();
    const client = useCurrentClient();

    const userTierOptions = getUserTierOptions(translate);

    const [clientName, setClientName] = useState(client.name);
    const [clientDefaultGroup, setClientDefaultGroup] = useState(client.defaultGroupId);
    const [clientDefaultUserTier, setClientDefaultUserTier] = useState(client.defaultUserTier);
    const [clientExternalUserGroup, setClientExternalUserGroup] = useState(client.externalUserGroupId);
    const [clientExternalUserTier, setClientExternalUserTier] = useState(client.externalUserTier);
    const [internalContactId, setInternalContactId] = useState(client.internalContactId);
    const [clientDefaultMeasureGroup, setClientDefaultMeasureGroup] = useState(client.defaultMeasureGroupId);

    const [touched, setTouched] = useState(false);
    const [logoDetails, setLogoDetails] = useState({ selectedFile: null as File | null, hasValidFile: false });

    const deleteDialog = useDialog();

    const filteredGroups = groupsQuery.data?.filter((group) => group.type === GroupType.STANDARD) ?? [];

    useEffect(() => {
        setClientName(client.name);
    }, [client.name]);

    const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTouched(true);
        setClientName(event.target.value);
    };

    const handleDefaultGroupChange = (group: number | null) => {
        setClientDefaultGroup(group);
        setTouched(true);
    };
    const handleDefaultTierChange = (tier: UserTier) => {
        setClientDefaultUserTier(tier);
        setTouched(true);
    };
    const handleExternalTierChange = (tier: UserTier) => {
        setClientExternalUserTier(tier);
        setTouched(true);
    };
    const handleExternalUserGroupChange = (group: number | null) => {
        setClientExternalUserGroup(group);
        setTouched(true);
    };
    const handleDefaultMeasureGroup = (group: number | null) => {
        setClientDefaultMeasureGroup(group);
        setTouched(true);
    };

    const selectedFileChanged = (selectedFile: File | null, hasValidFile: boolean) => setLogoDetails({ selectedFile, hasValidFile });

    const triggerFileUpload = () => {
        const file = logoDetails.selectedFile;

        if (file === null) {
            return;
        }

        const data = new FormData();
        data.append("file", file);
        updateClientLogo(data);
        setLogoDetails({ selectedFile: null, hasValidFile: false });
    };

    const submit = () => {
        if (!validateClientName(clientName)) {
            return;
        }
        const changes = {
            name: clientName,
            internalContactId,
            defaultGroupId: clientDefaultGroup,
            defaultUserTier: clientDefaultUserTier,
            externalUserGroupId: clientExternalUserGroup,
            externalUserTier: clientExternalUserTier,
            defaultMeasureGroupId: clientDefaultMeasureGroup,
        };
        updateClient(changes);
        setTouched(false);
    };

    if (!usersQuery.isSuccess || !suppliersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Grid container spacing={1} justifyContent="center">
            <DeleteDialog
                item="logo"
                translate={translate}
                open={deleteDialog.isOpen}
                onClose={deleteDialog.close}
                onDelete={() => deleteLogo()}
            />
            {/* use md=7 to force column layout because ie cannot handle flex column properly in some cases */}
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader title={translate("TeamInfo")} />
                    <CardContent>
                        <Form onSubmit={submit}>
                            <FormGroup>
                                <ValidatedInput
                                    label={translate("clientName")}
                                    name="clientName"
                                    value={clientName}
                                    onChange={handleNameInputChange}
                                    onInput={handleNameInputChange}
                                    isValid={validateClientName}
                                    mandatory
                                    margin="normal"
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputLabel sx={{ paddingBottom: 0.5 }} id="internalContactId">
                                    {translate("internalContactPerson")}
                                </InputLabel>
                                <SingleUser
                                    users={usersQuery.data}
                                    user={usersQuery.data.find((user) => user.id === internalContactId)}
                                    closeOnSelect={true}
                                    update={setInternalContactId}
                                    translate={translate}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ClientGroupSelect
                                    label={translate("defaultGroup")}
                                    groups={filteredGroups}
                                    onChange={(group) => handleDefaultGroupChange(group)}
                                    selectedGroup={clientDefaultGroup}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Select
                                    name="defaultusertier"
                                    label={`${translate("defaultGroup")} ${translate(translationKeys.VDLANG_USER_TIER)}`}
                                    value={getUserTierOption(clientDefaultUserTier, translate)}
                                    onChange={(option) => handleDefaultTierChange(option?.value ?? userTierOptions[0].value)}
                                    options={userTierOptions}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ClientGroupSelect
                                    label={translate("externalUserGroup")}
                                    groups={filteredGroups}
                                    onChange={(group) => handleExternalUserGroupChange(group)}
                                    selectedGroup={clientExternalUserGroup}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Select
                                    name="externalusertier"
                                    label={`${translate("externalUserGroup")} ${translate(translationKeys.VDLANG_USER_TIER)}`}
                                    value={getUserTierOption(clientExternalUserTier, translate)}
                                    onChange={(option) => handleExternalTierChange(option?.value ?? userTierOptions[0].value)}
                                    options={userTierOptions}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ClientGroupSelect
                                    label={translate(translationKeys.VDLANG_DEFAULT_PSEUDO_PUBLIC_MEASURE_GROUP)}
                                    groups={groupsQuery.data ?? []}
                                    onChange={(group) => handleDefaultMeasureGroup(group)}
                                    selectedGroup={clientDefaultMeasureGroup}
                                />
                            </FormGroup>
                        </Form>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={submit}
                            disabled={!validateClientName(clientName)}
                            variant={touched ? "contained" : "outlined"}
                            color="primary"
                        >
                            {translate(translationKeys.VDLANG_SAVE)}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader title={translate(translationKeys.VDLANG_UPDATE_LOGO)} />
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item>
                                {client.logoHash ? (
                                    <LogoImg alt="logo" src={`${baseUrl}static/${client.logoHash}`} />
                                ) : (
                                    <DefaultLogo color="action" />
                                )}
                            </Grid>
                            <Grid item flexGrow={1} flexShrink={1}>
                                <LogoUploadInput
                                    translate={translate}
                                    updateFile={selectedFileChanged}
                                    file={logoDetails.selectedFile}
                                    onlyImages
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button color="primary" disabled={!logoDetails.hasValidFile} onClick={triggerFileUpload}>
                            {translate(translationKeys.VDLANG_UPLOAD)}
                        </Button>
                        <DangerButton soft disabled={!client.logoHash} onClick={deleteDialog.open}>
                            {translate(translationKeys.VDLANG_DELETE_LOGO)}
                        </DangerButton>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={7}>
                <AdminDashboardColorConfiguration />
            </Grid>
        </Grid>
    );
};

export default TeamSetting;
