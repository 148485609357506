import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AclListDto, AclNamespaces, AclPermissions, EntityPermissionRequestBody, type CreateAclsRequestBody } from "api-shared";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const ADMIN_PERMISSIONS_PATH = "admin/permissions";

export const PermissionsQueryKeys = {
    all: ["permissions"] as const,
    permissionList: (showAllRules: boolean, namespacesFilter?: AclNamespaces[], permissionsFilter?: AclPermissions[]) =>
        [...PermissionsQueryKeys.all, `${ADMIN_PERMISSIONS_PATH}`, showAllRules, namespacesFilter, permissionsFilter] as const,
    entityPermissions: (data: EntityPermissionRequestBody) => ["entityPermissions", data] as const,
};

export const useAdminPermissions = (showAllRules: boolean, namespacesFilter?: AclNamespaces[], permissionsFilter?: AclPermissions[]) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.permissionList(showAllRules, namespacesFilter, permissionsFilter),
        queryFn: ({ signal }) => {
            const params = { showAllRules, namespaces: namespacesFilter, permissions: permissionsFilter };
            return apiGet<AclListDto>(`${ADMIN_PERMISSIONS_PATH}?${queryString.stringify(params, { arrayFormat: "comma" })}`, { signal });
        },
    });
};

export const useFilterUsersWithPermissionOnEntity = ({
    permission,
    entityIds,
    userIds,
    namespace,
    enabled = true,
}: EntityPermissionRequestBody & { enabled: boolean }) => {
    return useQuery({
        queryKey: PermissionsQueryKeys.entityPermissions({ permission, entityIds, userIds, namespace }),
        queryFn: ({ signal }) =>
            apiPost<number[]>(`${ADMIN_PERMISSIONS_PATH}/entity`, { permission, entityIds, userIds, namespace }, { signal }),
        enabled,
    });
};

export const useCreatePermissions = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateAclsRequestBody) => apiPost<AclListDto>(ADMIN_PERMISSIONS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries(PermissionsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PERMISSION_CREATED));
        },
    });
};

export const useAdminPermissionsDeleteUserAcl = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (aclId: number) => apiDelete(`${ADMIN_PERMISSIONS_PATH}/user/${aclId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(PermissionsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_PERMISSION_DELETED));
        },
    });
};

export const useAdminPermissionsDeleteGroupAcl = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (aclId: number) => apiDelete(`${ADMIN_PERMISSIONS_PATH}/group/${aclId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(PermissionsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_GROUP_PERMISSION_DELETED));
        },
    });
};
