import { FormControl } from "@mui/material";
import { GroupType } from "api-shared";
import Select from "../../../components/input/select/Select";
import { useGroups } from "../../../domain/admin/groups";

type GroupSelectProps = {
    label?: string;
    onChange?: (groupIds: number[]) => void;
    value?: number[];
};

export const GroupSelect = ({ label, onChange, value }: GroupSelectProps) => {
    const groups = useGroups();

    if (!groups.isSuccess) {
        return null;
    }

    const options =
        groups.data.map((group) => {
            return {
                label: group.name,
                value: group.id,
                isFixed: group.type !== GroupType.STANDARD,
            };
        }) ?? [];

    const selectedOptions = options.filter((option) => value?.includes(option.value));
    const hasNonFixedValues = options.some((v) => !v.isFixed);

    return (
        <FormControl fullWidth>
            <Select
                label={label}
                options={options}
                fullWidth
                isClearable={hasNonFixedValues}
                isMulti
                isSearchable
                isOptionDisabled={(option) => option.isFixed}
                onChange={(newSelectedOptions, actionMeta) => {
                    let newValues = newSelectedOptions;

                    switch (actionMeta.action) {
                        case "remove-value":
                        case "pop-value":
                            if (actionMeta.removedValue?.isFixed) {
                                return;
                            }
                            break;
                        case "clear":
                            newValues = selectedOptions.filter((v) => v.isFixed);
                            break;
                    }
                    const groupIds = newValues.map((option) => option.value);
                    onChange?.(groupIds);
                }}
                value={value !== undefined ? selectedOptions : undefined}
                menuPortalTarget={document.body}
            />
        </FormControl>
    );
};
