import { UserTier } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../../translations/main-translations";

export const getUserTierOption = (tier: UserTier, translate: TFunction) => ({
    value: tier,
    label: translate(`${translationKeys.VDLANG_USER_TIERS}.${tier}`),
});

export const getUserTierOptions = (t: TFunction) =>
    Object.values(UserTier)
        .filter((tier) => tier !== UserTier.SuperAdmin)
        .map((tier) => getUserTierOption(tier, t));
