import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, CreateGroupRequestBody, GroupDto, GroupListDto, UpdateGroupRequest } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import { UsersQueryKeys } from "../users";

export const GroupsQueryKeys = {
    all: ["groups"] as const,
    list: () => [...GroupsQueryKeys.all, "list"] as const,
};

const GROUP_PATH = "admin/groups";

export const useGroups = () => {
    return useQuery({
        queryKey: GroupsQueryKeys.list(),
        queryFn: ({ signal }) => apiGet<GroupListDto>(GROUP_PATH, { signal }),
    });
};

export const useCreateGroup = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateGroupRequestBody) => apiPost<GroupDto>(GROUP_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries(GroupsQueryKeys.list());
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_GROUP_CREATED));
        },
    });
};

export const useDeleteGroup = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (groupId: number) => apiDelete<BasicMessageDto>(`${GROUP_PATH}/${groupId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(GroupsQueryKeys.list());
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_GROUP_DELETED));
        },
    });
};

export const useUpdateGroupMutation = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ groupId, ...body }: UpdateGroupRequest) => apiPatch<GroupDto>(`${GROUP_PATH}/${groupId}`, body),
        onSuccess: () => {
            queryClient.invalidateQueries(GroupsQueryKeys.all);
            queryClient.invalidateQueries(UsersQueryKeys.current());
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_GROUP_UPDATED));
        },
    });
};
