import { Box, Stack } from "@mui/material";

import { CellProps } from "react-table";
import GroupAvatar from "../usergrouppermissions/GroupAvatar";

const TableGroupCell = <D extends object = Record<string, unknown>>({ value }: CellProps<D>) => {
    if (value == null) {
        return null;
    }
    return (
        <Stack direction="row" spacing={0.875} alignItems="center" maxWidth="100%" useFlexGap>
            <GroupAvatar size={24} />
            <Box minWidth={0}>{value}</Box>
        </Stack>
    );
};

export default TableGroupCell;
