import { AclListDto, AdminMeasureConfigListDto, AdminMeasureListDto, GroupDto, PlainDashboardDto, UserAclDto, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import BaseTable from "../../../components/table/BaseTable";
import TableGroupCell from "../../../components/table/TableGroupCell";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableIconButton from "../../../components/table/TableIconButton";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { useAdminPermissionsDeleteGroupAcl, useAdminPermissionsDeleteUserAcl } from "../../../domain/admin/permissions";
import { translationKeys } from "../../../translations/main-translations";
import AclTableRuleCell from "./AclTableRuleCell";
import { fullGroupPath } from "./PermissionSettings";

const INITIAL_SORT_BY = [
    {
        id: "assigneeId",
        desc: false,
    },
];

export enum AclAssigneeType {
    User = "user",
    Group = "group",
}

export type AclWithType = Omit<UserAclDto, "userId"> & {
    assigneeId: number;
    type: AclAssigneeType;
};

interface AclTableProps {
    acls: AclListDto;
    isFetching: boolean;
    measureConfigs?: AdminMeasureConfigListDto;
    users?: UserDto[];
    groups?: GroupDto[];
    globalFilter?: string;
    dashboards?: PlainDashboardDto[];
    measures?: AdminMeasureListDto;
}
const getTableOrUserCell = (props: CellProps<AclWithType>, users: UserDto[], groups: GroupDto[]) => {
    if (props.row.original.type === AclAssigneeType.User) {
        return <TableUserCell users={users} {...props} value={props.row.original.assigneeId} />;
    } else {
        return <TableGroupCell {...props} value={fullGroupPath(groups, props.value)} />;
    }
};

const getTableOrUserValue = (row: AclWithType, users: UserDto[], groups: GroupDto[]) => {
    if (row.type === AclAssigneeType.User) {
        const user = users.find((u) => u.id === row.assigneeId);
        return user?.displayname ?? user?.realname ?? row.assigneeId.toString();
    } else {
        const group = groups.find((g) => g.id === row.assigneeId);
        return group === undefined ? row.assigneeId : fullGroupPath(groups, row.assigneeId);
    }
};

const getDeleteCell = ({ row, value }: CellProps<AclWithType>, onClick: () => void, t: TFunction) => (
    <TableIconButton onClick={onClick}>
        <DeleteIcon />
    </TableIconButton>
);

export const AclTable = ({ acls, isFetching, measureConfigs, users, groups, globalFilter, dashboards, measures }: AclTableProps) => {
    const { t } = useTranslation();
    const deleteUserAclMutation = useAdminPermissionsDeleteUserAcl();
    const deleteGroupAclMutation = useAdminPermissionsDeleteGroupAcl();

    // We need to delay the global filter because the base table will not update the filtering otherwise :(
    const [delayedGlobalFilter, setDelayedGlobalFilter] = useState("");

    const [aclToDeleteId, setAclToDeleteId] = useState<number | undefined>(undefined);

    const mergedAcls = useMemo<AclWithType[]>(() => {
        const userAcls = acls.userAcls.map((acl) => ({ ...acl, assigneeId: acl.userId, type: AclAssigneeType.User }));
        const groupAcls = acls.groupAcls.map((acl) => ({ ...acl, assigneeId: acl.groupId, type: AclAssigneeType.Group }));

        return [...userAcls, ...groupAcls];
    }, [acls.groupAcls, acls.userAcls]);

    useEffect(() => {
        setDelayedGlobalFilter(globalFilter ?? "");
    }, [globalFilter, mergedAcls]);

    const onRemove = () => {
        const aclDel = mergedAcls.find((acl) => acl.id === aclToDeleteId);
        if (aclDel === undefined) {
            return;
        }

        setDelayedGlobalFilter("");
        if (aclDel.type === AclAssigneeType.User) {
            deleteUserAclMutation.mutate(aclDel.id);
        } else {
            deleteGroupAclMutation.mutate(aclDel.id);
        }
    };

    const renderUserOrGroup = useMemo(
        () => (props: CellProps<AclWithType>) => getTableOrUserCell(props, users ?? [], groups ?? []),
        [groups, users],
    );

    const columns = useMemo<Column<AclWithType>[]>(
        () => [
            {
                id: "assigneeId",
                accessor: (acl) => getTableOrUserValue(acl, users ?? [], groups ?? []),
                label: t(translationKeys.VDLANG_ACL_USER_GROUP),
                Header: TableHeaderCell,
                Cell: renderUserOrGroup,
            },
            {
                id: "namespace",
                accessor: (acl) => t(`${translationKeys.VDLANG_ACL_NAMESPACES}.${acl.namespace}`),
                label: t(translationKeys.VDLANG_ACL_NAMESPACE),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "permission",
                accessor: (acl) => t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`),
                label: t(translationKeys.VDLANG_ACL_PERMISSION),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "rule",
                accessor: (acl) => acl,
                label: t(translationKeys.VDLANG_ACL_RULE),
                Header: TableHeaderCell,
                Cell: (props: CellProps<AclWithType>) => (
                    <AclTableRuleCell {...props} measureConfigs={measureConfigs} dashboards={dashboards} measures={measures} />
                ),
            },
            {
                Header: "",
                accessor: "id",
                id: "delete",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (cell) => getDeleteCell(cell, () => setAclToDeleteId(cell.value), t),
            },
        ],
        [groups, renderUserOrGroup, t, users, measureConfigs, dashboards, measures],
    );

    return (
        <>
            <DeleteDialog
                item="rule"
                translate={t}
                open={aclToDeleteId !== undefined}
                onClose={() => setAclToDeleteId(undefined)}
                onDelete={onRemove}
            />
            <BaseTable
                fullHeight
                isFetching={isFetching}
                data={mergedAcls}
                defaultSortBy={INITIAL_SORT_BY}
                columns={columns}
                itemName="acls"
                translate={t}
                globalFilter={delayedGlobalFilter}
                noDataText={t(translationKeys.VDLANG_PERMISSION_DIALOG_NO_RULES_FOUND)}
            />
        </>
    );
};
