import { Typography, TypographyProps } from "@mui/material";
import {
    AclCondition,
    AclNamespaces,
    AdminMeasureConfigListDto,
    AdminMeasureListDto,
    MeasureFieldNames,
    PlainDashboardDto,
} from "api-shared";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import MeasureLink from "../../../components/tasks/MeasureLink";
import { mapConstantsToTranslations } from "../../../lib/fields";
import { translationKeys } from "../../../translations/main-translations";
import { AclWithType } from "./AclTable";

export interface IAclTableRuleCellProps<D extends object = Record<string, unknown>>
    extends Partial<Pick<CellProps<D>, "value" | "cell">>,
        Pick<TypographyProps, "align" | "variant" | "className"> {
    measureConfigs?: AdminMeasureConfigListDto;
    dashboards?: PlainDashboardDto[];
    measures?: AdminMeasureListDto;
}

const AclTableRuleCell = <D extends object = Record<string, unknown>>({
    value,
    measureConfigs,
    dashboards,
    measures,
    align,
    variant,
    className,
}: IAclTableRuleCellProps<D>) => {
    const { t } = useTranslation();

    const acl = value as AclWithType;

    function formatFact(condition: AclCondition): ReactNode {
        let factPart: ReactNode = condition.fact;

        switch (condition.fact) {
            case "id":
                factPart = t(translationKeys.VDLANG_ID);
                break;
            case "status":
                factPart = t(translationKeys.VDLANG_ACL_TABLE_STATUS);
                break;
            case "visibility":
                factPart = t("visibility");
                break;
        }

        return factPart;
    }

    function formatOperator(condition: AclCondition): ReactNode {
        let operatorPart: ReactNode = "=";

        switch (condition.operator) {
            case "notEqual":
                operatorPart = <span>&#8800;</span>;
                break;
            case "lessThan":
                operatorPart = "<";
                break;
            case "lessThanInclusive":
                operatorPart = <span>&#8804;</span>;
                break;
            case "greaterThan":
                operatorPart = ">";
                break;
            case "greaterThanInclusive":
                operatorPart = <span>&#8805;</span>;
                break;
        }

        return operatorPart;
    }

    function formatValue(condition: AclCondition): ReactNode {
        const namespace = acl.namespace;

        let valuePart: ReactNode = condition.value;

        if (namespace === AclNamespaces.Process) {
            let key = null;
            if (condition.fact === "status") {
                key = mapConstantsToTranslations(MeasureFieldNames.Status, condition.value.toString());
            }
            if (condition.fact === "visibility") {
                key = mapConstantsToTranslations(MeasureFieldNames.Visibility, condition.value.toString());
            }
            if (key !== null) {
                valuePart = `"${t(key)}"`;
            }
            if (condition.fact === "id" && condition.operator === "equal") {
                valuePart = `${condition.value} - ${measures?.find((m) => m.id === condition.value)?.title}`;
            }
        } else if (namespace === AclNamespaces.Dashboard && condition.fact === "id") {
            const dashboard = dashboards?.find((d) => d.id === condition.value);
            let dashboardName = dashboard?.name;
            if (dashboardName === "VDLANG_DASHBOARD_DEFAULT_NAME") {
                dashboardName = t(translationKeys.VDLANG_DASHBOARD_DEFAULT_NAME);
            }
            valuePart = `${condition.value} - ${dashboardName}`;
        } else if (namespace === AclNamespaces.Valuestream && condition.fact === "id") {
            const mc = measureConfigs?.find((mc) => mc.id === condition.value);
            valuePart = `${condition.value} - ${mc ? t(mc.name) : condition.value}`;
        }

        return valuePart;
    }

    const formatRule = (condition: AclCondition, index: number): ReactNode => {
        const ruleCount = acl.rule.length;

        if (condition.operator === "*") {
            if (ruleCount === 1) {
                return "*";
            }
            return `${condition.fact} = *`;
        }

        if (ruleCount === 1 && acl.namespace === AclNamespaces.Process && condition.operator === "equal" && condition.fact === "id") {
            const measure = measures?.find((m) => m.id === condition.value);
            return <MeasureLink measureId={measure?.id ?? -1} title={measure?.title ?? ""} clientIid={measure?.clientIid ?? -1} />;
        }

        const factPart = formatFact(condition);
        const operatorPart = formatOperator(condition);
        const valuePart = formatValue(condition);

        return (
            <span key={`acl_${index}`}>
                {factPart} {operatorPart} {valuePart}
            </span>
        );
    };

    return acl != null ? (
        <Typography variant={variant} align={align} noWrap className={className}>
            {acl.rule.map((r, idx) => formatRule(r, idx))}
        </Typography>
    ) : null;
};

export default AclTableRuleCell;
