import { GroupDto } from "api-shared";
import { OnChangeValue } from "react-select";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";

interface IClientGroupSelect {
    label: string;
    groups: GroupDto[];
    onChange: (groupId: number | null) => void;
    selectedGroup: number | null;
}

const ClientGroupSelect = ({ label, groups, onChange, selectedGroup }: IClientGroupSelect) => {
    const handleChange = (option: OnChangeValue<Option, false>) => {
        onChange(option?.value ?? null);
    };

    const groupsOptions = groups.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    return (
        <Select
            label={label}
            value={groupsOptions.find((group) => group.value === selectedGroup)}
            options={groupsOptions}
            onChange={handleChange}
            isClearable
            isSearchable
            menuPortalTarget={document.body}
            margin="dense"
        />
    );
};

export default ClientGroupSelect;
