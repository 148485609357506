import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
    Popover,
    Stack,
    Typography,
    formControlClasses,
    styled,
} from "@mui/material";
import { AclNamespaces, AclPermissions, GroupDto } from "api-shared";
import { forIn } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import SearchInput from "../../../components/input/SearchInput";
import Select from "../../../components/input/select/Select";
import SelectButton from "../../../components/input/select/SelectButton";
import SelectStaticMenuContainer from "../../../components/input/select/components/SelectStaticMenu";
import { useAdminDashboards } from "../../../domain/admin/dashboards";
import { useGroups } from "../../../domain/admin/groups";
import { useAdminMeasureConfigs, useAdminMeasures } from "../../../domain/admin/measures";
import { useAdminPermissions } from "../../../domain/admin/permissions";
import { useAdminUsers } from "../../../domain/admin/users";
import { useDebounce } from "../../../hooks/useDebounce";
import useMenu from "../../../hooks/useMenu";
import { getFullGroupPath } from "../../../lib/groups";
import { renderPath } from "../../../lib/tree";
import { translationKeys } from "../../../translations/main-translations";
import { AclTable } from "./AclTable";
import AddRulesHandling from "./AddRulesHandling";

const Root = styled(Stack)(() => ({
    height: "100%",
}));

const PopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(30),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const FixWidthSelectButton = styled(SelectButton)(({ theme }) => ({
    width: theme.spacing(22),
}));

export const fullGroupPath = (groups: GroupDto[], id: number): string => {
    const group = groups.find((g) => g.id === id);
    if (group === undefined) {
        return id.toString();
    }

    const path = getFullGroupPath(groups, group);
    return renderPath(path);
};

type NamespaceOption = { label: string; value: AclNamespaces };
type PermissionOption = { label: string; value: AclPermissions };

const PermissionSettings = () => {
    const { t } = useTranslation();

    const [showAllPermissions, setShowAllPermissions] = useState(false);
    const [selectedNamespaces, setSelectedNamespaces] = useState<NamespaceOption[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>([]);
    const permissionsQuery = useAdminPermissions(
        showAllPermissions,
        selectedNamespaces.map((ns) => ns.value),
        selectedPermissions.map((p) => p.value),
    );
    const measureConfigs = useAdminMeasureConfigs();
    const dashboards = useAdminDashboards();
    const measures = useAdminMeasures();
    const users = useAdminUsers();
    const groups = useGroups();
    const menuNamespaces = useMenu();
    const menuPermissions = useMenu();

    const [filter, setFilter] = useState("");
    const tableFilterValue = useDebounce(filter);

    const handleShowAllPermissions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowAllPermissions(event.target.checked);
    };

    const handleNamespaceChange = (newSelectedOptions: MultiValue<NamespaceOption>) => {
        setSelectedNamespaces(newSelectedOptions as NamespaceOption[]);
    };

    const handlePermissionChange = (newSelectedOptions: MultiValue<PermissionOption>) => {
        setSelectedPermissions(newSelectedOptions as PermissionOption[]);
    };

    let namespaceOptions: NamespaceOption[] = [];
    forIn(AclNamespaces, (value, key) => {
        if (isNaN(Number(key)) && value !== AclNamespaces.SuperAdmin) {
            namespaceOptions.push({ value, label: t(`${translationKeys.VDLANG_ACL_NAMESPACES}.${value}`) });
        }
    });
    namespaceOptions = namespaceOptions.toSorted((a, b) => a.label.localeCompare(b.label));

    let permissionOptions: PermissionOption[] = [];
    forIn(AclPermissions, (value, key) => {
        if (isNaN(Number(key))) {
            permissionOptions.push({ value, label: t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${value}`) });
        }
    });
    permissionOptions = permissionOptions.toSorted((a, b) => a.label.localeCompare(b.label));

    return (
        <Root spacing={2}>
            <Paper sx={{ height: "100%" }}>
                <Stack sx={{ height: "100%" }}>
                    <Box sx={{ px: 3, py: 2 }}>
                        <Typography variant="subtitle1">{t(translationKeys.VDLANG_ACL_MANAGE_ACCESS)}</Typography>
                    </Box>
                    <Divider />
                    <Stack sx={{ px: 3, py: 1.5 }} display="flex" direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1}>
                            <SearchInput translate={t} searchKey={filter} onChange={setFilter} fullWidth={false} />
                            <FormGroup>
                                <Popover {...menuNamespaces.menuProps}>
                                    <PopoverContainer>
                                        <Select
                                            isMulti
                                            options={namespaceOptions}
                                            value={selectedNamespaces}
                                            onChange={(newSelectedOptions) => handleNamespaceChange(newSelectedOptions)}
                                            menuIsOpen
                                            isClearable
                                            isSearchable
                                            placeholder={`${t("Search")}...`}
                                            margin="none"
                                            components={{
                                                Menu: SelectStaticMenuContainer,
                                                DropdownIndicator: undefined,
                                            }}
                                        />
                                    </PopoverContainer>
                                </Popover>
                                <FixWidthSelectButton
                                    label={t(translationKeys.VDLANG_ACL_NAMESPACE)}
                                    count={selectedNamespaces.length}
                                    size="medium"
                                    onClick={menuNamespaces.openMenu}
                                    aria-expanded={menuNamespaces.menuProps.open ? "true" : "false"}
                                    aria-haspopup="true"
                                    fullWidth={false}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Popover {...menuPermissions.menuProps}>
                                    <PopoverContainer>
                                        <Select
                                            isMulti
                                            options={permissionOptions}
                                            value={selectedPermissions}
                                            onChange={(newSelectedOptions) => handlePermissionChange(newSelectedOptions)}
                                            menuIsOpen
                                            isClearable
                                            isSearchable
                                            placeholder={`${t("Search")}...`}
                                            margin="none"
                                            components={{
                                                Menu: SelectStaticMenuContainer,
                                                DropdownIndicator: undefined,
                                            }}
                                        />
                                    </PopoverContainer>
                                </Popover>
                                <FixWidthSelectButton
                                    label={t(translationKeys.VDLANG_ACL_PERMISSION)}
                                    count={selectedPermissions.length}
                                    size="medium"
                                    onClick={menuPermissions.openMenu}
                                    aria-expanded={menuPermissions.menuProps.open ? "true" : "false"}
                                    aria-haspopup="true"
                                    fullWidth={false}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox size="small" defaultChecked={showAllPermissions} onChange={handleShowAllPermissions} />
                                    }
                                    label={t(translationKeys.VDLANG_ACL_SHOW_ALL_PERMISSIONS)}
                                />
                            </FormGroup>
                        </Stack>
                        <AddRulesHandling
                            users={users.data ?? []}
                            groups={groups.data ?? []}
                            dashboards={dashboards.data ?? []}
                            measures={measures.data ?? []}
                            measureConfigs={measureConfigs.data ?? []}
                        />
                    </Stack>
                    <Divider />
                    {permissionsQuery.isSuccess ? (
                        <AclTable
                            key={`acl-table-${showAllPermissions}-${selectedNamespaces.length}-${selectedPermissions.length}`}
                            acls={permissionsQuery.data}
                            isFetching={
                                permissionsQuery.isLoading ||
                                users.isLoading ||
                                groups.isLoading ||
                                measureConfigs.isLoading ||
                                dashboards.isLoading
                            }
                            measureConfigs={measureConfigs.data}
                            dashboards={dashboards.data}
                            measures={measures.data}
                            users={users.data}
                            groups={groups.data}
                            globalFilter={tableFilterValue}
                        />
                    ) : null}
                </Stack>
            </Paper>
        </Root>
    );
};

export default PermissionSettings;
