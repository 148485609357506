import SupervisorAccountRounded from "@mui/icons-material/SupervisorAccountRounded";
import { Button, Divider, Stack, Tooltip } from "@mui/material";
import { AdminUserDto, GroupDto, GroupType, UserDto } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import InfoIcon from "../../../components/icons/InfoIcon";
import SearchInput from "../../../components/input/SearchInput";
import BaseTable from "../../../components/table/BaseTable";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import TableTextCell from "../../../components/table/TableTextCell";
import TableUserCell from "../../../components/table/TableUserCell";
import { useUpdateGroupMutation } from "../../../domain/admin/groups";
import { useDebounce } from "../../../hooks/useDebounce";
import useDialog from "../../../hooks/useDialog";
import { compareUsersByDisplayName } from "../../../lib/sort";
import { translationKeys } from "../../../translations/main-translations";
import { UserDialog } from "./UserDialog";
import { UserTableDeleteCell } from "./UserTableDeleteCell";

const EMPTY_USERS: UserDto[] = [];
const INITIAL_SORT_BY = [
    {
        id: "displayname",
        desc: false,
    },
];

interface UserTableProps {
    currentGroup: GroupDto | null;
    allUsers: AdminUserDto[];
}

export const UserTable = ({ currentGroup, allUsers }: UserTableProps) => {
    const { t } = useTranslation();

    const [searchKey, setSearchKey] = useState("");
    const [userToDeleteId, setUserToDeleteId] = useState<number | undefined>(undefined);

    const usersToShow = useMemo(() => {
        return allUsers.filter((user) => currentGroup?.userIds.includes(user.id));
    }, [currentGroup, allUsers]);

    const updateGroupMutation = useUpdateGroupMutation();

    const debouncedSearchKey = useDebounce(searchKey);
    const userDialog = useDialog();

    const isSpecialGroup = currentGroup?.type !== GroupType.STANDARD;

    const renderUser = useCallback(
        ({ value, ...props }: CellProps<UserDto>) => {
            const user = usersToShow.find((user) => user.id === value);
            return <TableUserCell value={user} users={usersToShow} {...props} />;
        },
        [usersToShow],
    );

    const userColumns = useMemo<Column<UserDto>[]>(
        () => [
            {
                id: "displayname",
                accessor: "id",
                label: t("displayname"),
                Header: TableHeaderCell,
                Cell: renderUser,
                sortType: (rowA, rowB) => compareUsersByDisplayName(rowA.original, rowB.original),
            },
            {
                id: "realname",
                accessor: (user) => user.realname,
                label: t("realname"),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "email",
                accessor: (user) => user.email,
                label: t("email"),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                id: "tier",
                accessor: (user) => t(`${translationKeys.VDLANG_USER_TIERS}.${user.tier}`),
                label: t(translationKeys.VDLANG_USER_TIER),
                Header: TableHeaderCell,
                Cell: TableTextCell,
            },
            {
                Header: "",
                accessor: "id",
                id: "remove",
                width: 64,
                disableResizing: true,
                disableSortBy: true,
                Cell: (cell) => <UserTableDeleteCell cell={cell} onClick={setUserToDeleteId} disabled={isSpecialGroup} />,
            },
        ],
        [t, renderUser, isSpecialGroup],
    );

    const onRemove = () => {
        if (userToDeleteId === undefined || currentGroup === null) {
            return;
        }
        updateGroupMutation.mutate({
            ...currentGroup,
            groupId: currentGroup.id,
            userIds: currentGroup.userIds.filter((id) => id !== userToDeleteId),
        });
        setUserToDeleteId(undefined);
    };

    return (
        <>
            <DeleteDialog
                item="usergroup"
                translate={t}
                open={userToDeleteId !== undefined}
                onClose={() => setUserToDeleteId(undefined)}
                onDelete={onRemove}
            />
            {currentGroup !== null && (
                <UserDialog
                    allUsers={allUsers}
                    currentUserIds={currentGroup?.userIds ?? []}
                    open={userDialog.isOpen}
                    onClose={() => userDialog.close()}
                    currentGroup={currentGroup}
                />
            )}
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 3, py: 1.5 }}>
                <SearchInput searchKey={searchKey} onChange={setSearchKey} translate={t} fullWidth={false} />
                <Button
                    variant="contained"
                    startIcon={<SupervisorAccountRounded />}
                    onClick={() => userDialog.open()}
                    disabled={isSpecialGroup}
                >
                    {t(translationKeys.VDLANG_ADMIN_GROUPS_MANAGE_USERS_BUTTON)}
                    {isSpecialGroup ? (
                        <Tooltip title={t(translationKeys.VDLANG_ADMIN_GROUPS_SPECIAL_DISABLED)} style={{ pointerEvents: "auto" }}>
                            <span>
                                <InfoIcon sx={{ ml: 0.5 }} />
                            </span>
                        </Tooltip>
                    ) : null}
                </Button>
            </Stack>
            <Divider />

            <BaseTable
                fullHeight
                isFetching={false}
                data={usersToShow ?? EMPTY_USERS}
                defaultSortBy={INITIAL_SORT_BY}
                columns={userColumns}
                itemName="users"
                translate={t}
                globalFilter={debouncedSearchKey}
                noDataText={t(translationKeys.VDLANG_ADMIN_GROUPS_NO_USERS)}
                disablePagination
            />
        </>
    );
};
