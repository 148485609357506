import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, Typography, styled } from "@mui/material";
import { CreateGroupRequestBody, GroupDto, zCreateGroupRequestBody } from "api-shared";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { useCreateGroup, useUpdateGroupMutation } from "../../../domain/admin/groups";
import { translationKeys } from "../../../translations/main-translations";

const GROUP_DEFAULT_VALUES: CreateGroupRequestBody = {
    name: "",
    description: "",
    parentGroupId: null,
    isVisible: true,
};

type GroupDialogProps = {
    open?: boolean;
    currentGroup?: GroupDto;
    onClose?: () => void;
    parentGroupId: number | null;
    ancestors: GroupDto[];
};

const DerivedTag = styled("span")(({ theme }) => ({
    color: theme.palette.text.disabled,
    marginLeft: theme.spacing(1.5),
}));

export const GroupDialog = ({ open = false, currentGroup, onClose = () => void 0, parentGroupId, ancestors }: GroupDialogProps) => {
    const { t } = useTranslation();

    const createGroupMutation = useCreateGroup();
    const updateGroupMutation = useUpdateGroupMutation();
    const isNewGroup = currentGroup === undefined;

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors, isValid },
    } = useForm<CreateGroupRequestBody>({
        mode: "onChange",
        resolver: zodResolver(zCreateGroupRequestBody),
        defaultValues: currentGroup ?? GROUP_DEFAULT_VALUES,
    });

    useEffect(() => {
        if (open) {
            reset(currentGroup ?? GROUP_DEFAULT_VALUES);
        }
    }, [currentGroup, open, reset]);

    if (!open) {
        return null;
    }
    const isHiddenByAncestor = ancestors.some((a) => a.id !== currentGroup?.id && !a.isVisible);

    const onSubmit = handleSubmit((data) => {
        if (!isNewGroup) {
            updateGroupMutation.mutate(
                {
                    groupId: currentGroup.id,
                    ...data,
                    userIds: currentGroup.userIds,
                    isVisible: isHiddenByAncestor ? false : data.isVisible,
                },
                { onSuccess: () => reset() },
            );
        } else {
            createGroupMutation.mutate(
                { ...data, parentGroupId, isVisible: isHiddenByAncestor ? false : data.isVisible },
                { onSuccess: () => reset() },
            );
        }

        onClose();
    });

    const title = t(isNewGroup ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_CREATE : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE);
    const primaryButton = isNewGroup
        ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_CREATE_BUTTON
        : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE_BUTTON;

    return (
        <ActionItemDialog
            open={open}
            title={title}
            primary={primaryButton}
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onClose}
            translate={t}
        >
            <Form onSubmit={onSubmit}>
                <Stack spacing={2}>
                    <TextField
                        autoFocus
                        fullWidth
                        label={t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_NAME)}
                        error={errors.name !== undefined}
                        helperText={
                            errors.name &&
                            t(
                                errors.name.type.includes("too_big")
                                    ? translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_LONG
                                    : translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_SHORT,
                            )
                        }
                        {...register("name")}
                    />
                    <TextField
                        fullWidth
                        label={t(translationKeys.VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION)}
                        error={errors.description !== undefined}
                        helperText={errors.description && t(translationKeys.VDLANG_ADMIN_GROUPS_DIALOG_DESCRIPTION_ERROR)}
                        {...register("description")}
                    />
                    <Controller
                        name="isVisible"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Typography variant="body2" color={(theme) => theme.palette.text.secondary}>
                                        {t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBILITY)}
                                    </Typography>
                                    <Tooltip title={t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBLE_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                    indeterminate={isHiddenByAncestor}
                                                    color={isHiddenByAncestor ? "default" : "primary"}
                                                />
                                            }
                                            label={
                                                <span>
                                                    {t(translationKeys.VDLANG_ADMIN_GROUPS_VISIBLE)}
                                                    {isHiddenByAncestor && (
                                                        <DerivedTag>{`(${t(translationKeys.VDLANG_ADMIN_GROUPS_PERMISSION_DERIVED)})`}</DerivedTag>
                                                    )}
                                                </span>
                                            }
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />
                </Stack>
            </Form>
        </ActionItemDialog>
    );
};
