import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { AclNamespaces, AclPermissions, CreateAclsRequestBody, GroupDto, PlainDashboardDto, UserDto } from "api-shared";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";

import { TFunction } from "i18next";
import { z } from "zod";
import Select from "../../../components/input/select/Select";
import { translationKeys } from "../../../translations/main-translations";
import { MAX_OPTIONS_COUNT_PERMISSION_DIALOGS } from "./AddRulesHandling";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds"> & { dashboardId: number };

type DashboardDialogProps = {
    open?: boolean;
    onClose?: () => void;
    dashboards: PlainDashboardDto[];
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

const getDashboardConfigOptions = (t: TFunction, dashboards?: PlainDashboardDto[]) => {
    if (dashboards === undefined) {
        return [];
    } else {
        return [
            ...dashboards
                .map(({ id, name }) => ({
                    value: id,
                    label: `${name === "VDLANG_DASHBOARD_DEFAULT_NAME" ? t(translationKeys.VDLANG_DASHBOARD_DEFAULT_NAME) : name} (ID = ${id})`,
                }))
                .toSorted((a, b) => a.label.localeCompare(b.label)),
        ];
    }
};

export const DashboardDialog = ({
    open = false,
    onClose = () => {},
    dashboards,
    users,
    groups,
    onPermissionSubmit,
}: DashboardDialogProps) => {
    const { t } = useTranslation();

    const dashboardOptions = getDashboardConfigOptions(t, dashboards);

    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: zodResolver(zFormUserGroupSchema.and(z.object({ dashboardId: z.number().int().min(0) }))),
        defaultValues: { userIds: [], groupIds: [], dashboardId: undefined },
    });

    const onSubmit = handleSubmit((data) => {
        if (data.dashboardId !== null) {
            onPermissionSubmit({
                groupIds: data.groupIds,
                userIds: data.userIds,
                acl: {
                    namespace: AclNamespaces.Dashboard,
                    permission: AclPermissions.Read,
                    rule: [{ fact: "id", operator: "equal", value: data.dashboardId }],
                    simpleEntityId: data.dashboardId,
                },
            });
        }
        onClose();
    });

    const onCancel = () => {
        onClose();
        reset();
    };

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_DASHBOARD)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack gap={1}>
                    <Controller
                        name="dashboardId"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <Select
                                    margin="none"
                                    label={t(translationKeys.VDLANG_ACL_TITLE_DASHBOARD)}
                                    value={dashboardOptions.find((t) => t.value === value)}
                                    options={dashboardOptions}
                                    onChange={(o) => onChange(o?.value ?? 0)}
                                    menuPortalTarget={document.body}
                                    placeholder={`${t("Search")}...`}
                                    isSearchable={true}
                                    maxOptionsCount={MAX_OPTIONS_COUNT_PERMISSION_DIALOGS}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
