import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox, FormControlLabel, FormGroup, Stack, styled } from "@mui/material";
import {
    AclConditionStar,
    AclNamespaces,
    AclPermissions,
    AclRule,
    CreateAclsRequestBody,
    FeatureFlags,
    GroupDto,
    UserDto,
} from "api-shared";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Tooltip from "../../../components/Tooltip";
import { useClientHasFeature } from "../../../domain/client";
import { translationKeys } from "../../../translations/main-translations";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds"> & {
    alwaysDecide: boolean;
    canDecideProcessesOnOwn: boolean;
    canBeResponsibleForProcesses: boolean;
};

type MeasurePermissionDialogProps = {
    open: boolean;
    onClose: () => void;
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

const customResolver: Resolver<FormValues> = async (values, context, options) => {
    if (!values.alwaysDecide && !values.canDecideProcessesOnOwn && !values.canBeResponsibleForProcesses) {
        return {
            values,
            errors: { read: "At least one permission type is required", write: "At least one permission type is required" },
        };
    }
    const zod = zodResolver(
        zFormUserGroupSchema.and(
            z.object({
                alwaysDecide: z.boolean(),
                canDecideProcessesOnOwn: z.boolean(),
                canBeResponsibleForProcesses: z.boolean(),
            }),
        ),
    );
    return zod(values, context, options);
};

const ReducedPaddingCheckbox = styled(Checkbox)(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

export const MeasurePermissionDialog = ({
    open = false,
    onClose = () => {},
    users,
    groups,
    onPermissionSubmit,
}: MeasurePermissionDialogProps) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: customResolver,
        defaultValues: {
            userIds: [],
            groupIds: [],
            alwaysDecide: false,
            canDecideProcessesOnOwn: false,
            canBeResponsibleForProcesses: false,
        },
    });

    const hasProcessSelfDecisionFeature = useClientHasFeature(FeatureFlags.FEATURE_PROCESS_SELF_DECISION); // canDecideProcessesOnOwn

    const createPermissionAcl = (data: FormValues, namespace: AclNamespaces, permission: AclPermissions) => {
        const rule: AclRule = [];
        rule.push(AclConditionStar);

        onPermissionSubmit({
            groupIds: data.groupIds,
            userIds: data.userIds,
            acl: {
                namespace: namespace,
                permission: permission,
                rule: rule,
                simpleEntityId: null,
            },
        });
    };

    const onSubmit = handleSubmit((data) => {
        if (data.alwaysDecide) {
            createPermissionAcl(data, AclNamespaces.Process, AclPermissions.Decide);
        }
        if (data.canDecideProcessesOnOwn && hasProcessSelfDecisionFeature) {
            createPermissionAcl(data, AclNamespaces.Process, AclPermissions.SelfDecision);
        }
        if (data.canBeResponsibleForProcesses) {
            createPermissionAcl(data, AclNamespaces.Process, AclPermissions.Responsible);
        }

        onClose();
    });

    const onCancel = () => {
        onClose();
        reset();
    };

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_MEASURE_PERMISSION)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack gap={1}>
                    <Controller
                        name="alwaysDecide"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />
                    {hasProcessSelfDecisionFeature && (
                        <Controller
                            name="canDecideProcessesOnOwn"
                            control={control}
                            render={({ field }) => {
                                const { value, onChange } = field;
                                return (
                                    <FormGroup>
                                        <Tooltip title={t(translationKeys.VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN_HINT)}>
                                            <FormControlLabel
                                                control={
                                                    <ReducedPaddingCheckbox
                                                        size="small"
                                                        checked={value}
                                                        onChange={(_, checked) => onChange(checked)}
                                                    />
                                                }
                                                label={t(translationKeys.VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN)}
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                );
                            }}
                        />
                    )}
                    <Controller
                        name="canBeResponsibleForProcesses"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <FormGroup>
                                    <Tooltip title={t(translationKeys.VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES_HINT)}>
                                        <FormControlLabel
                                            control={
                                                <ReducedPaddingCheckbox
                                                    size="small"
                                                    checked={value}
                                                    onChange={(_, checked) => onChange(checked)}
                                                />
                                            }
                                            label={t(translationKeys.VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES)}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            );
                        }}
                    />

                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
