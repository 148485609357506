import CloseRounded from "@mui/icons-material/CloseRounded";
import { IconButton, List, ListItemSecondaryAction, styled } from "@mui/material";
import { FeatureFlags } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, Route, useLocation } from "react-router-dom";
import RootRoutes from "../../components/RootRoutes";
import SidebarLayout from "../../components/SidebarLayout";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import SidebarNavItemButton from "../../components/sidebar/SidebarNavItemButton";
import SidebarNavItemGroup from "../../components/sidebar/SidebarNavItemGroup";
import SidebarPreferences from "../../components/sidebar/SidebarPreferences";
import SidebarTitleItem from "../../components/sidebar/SidebarTitleItem";
import { useAdminDomains } from "../../domain/admin/domains";
import { useAdminIdentityProviders } from "../../domain/admin/signon";
import { useAdminUsers } from "../../domain/admin/users";
import { useClientHasFeature, useCurrentClient } from "../../domain/client";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useIsMobile } from "../../lib/mobile";
import { RouteFor } from "../../lib/routes";
import { translationKeys } from "../../translations/main-translations";
import CompaniesSettings from "./companies/CompaniesSettings";
import CurrencySettings from "./currency/CurrencySettings";
import DepartmentsSettings from "./departments/DepartmentsSettings";
import DomainsSettings from "./domains/DomainsSettings";
import { GroupSettings } from "./groups/GroupSettings";
import MeasuresSettings from "./measures/MeasuresSettings";
import MembersSettings from "./members/MembersSettings";
import OppsSettings from "./opportunities/OppsSettings";
import PermissionSettings from "./permissions/PermissionSettings";
import SignOnSettings from "./signon/SignOnSettings";
import SupplierManagement from "./suppliers/SupplierManagement";
import TeamSetting from "./team/TeamSetting";
import TreeNodeSettings from "./tree-nodes/components/TreeNodeSettings";

const Root = styled("div")(({ theme }) => ({
    padding: theme.spacing(3, 2),
    height: "100%",
    overflowY: "auto",
}));

type TabItem = {
    path: string;
    title: translationKeys;
};

type TabGroup = {
    title: translationKeys;
    items: TabItem[];
};

const AdminView = () => {
    useDocumentTitle(translationKeys.VDLANG_SECTIONS_ADMIN);

    const { t: translate } = useTranslation();
    const location = useLocation();
    const usersQuery = useAdminUsers();
    const domainsQuery = useAdminDomains();
    const identityProvidersQuery = useAdminIdentityProviders();

    const hasOpportunitySpace = useClientHasFeature(FeatureFlags.FEATURE_IDEA_SECTION);
    const isMobileView = useIsMobile();
    const client = useCurrentClient();

    const [isOpen, setIsOpen] = useState(false);

    if (!(client && usersQuery.isSuccess && domainsQuery.isSuccess && identityProvidersQuery.isSuccess)) {
        return <LoadingAnimation />;
    }

    const tabGroups: TabGroup[] = [
        {
            title: translationKeys.VDLANG_ADMIN_SIDEBAR_GROUP_SETTINGS,
            items: [
                {
                    path: RouteFor.admin.organization,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_SETTINGS_ORGANIZATION,
                },
            ],
        },
        {
            title: translationKeys.VDLANG_ADMIN_SIDEBAR_GROUP_DATA,
            items: [
                {
                    path: RouteFor.admin.companies,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_COMPANIES,
                },
                {
                    path: RouteFor.admin.treeNodes,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_TREE_NODES,
                },
                {
                    path: RouteFor.admin.suppliers,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_SUPPLIERS,
                },
                {
                    path: RouteFor.admin.departments,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_DEPARTMENTS,
                },
                {
                    path: RouteFor.admin.measures,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_PROCESSES,
                },
            ],
        },
        {
            title: translationKeys.VDLANG_ADMIN_SIDEBAR_GROUP_USERS_AND_ACCESS,
            items: [
                {
                    path: RouteFor.admin.users,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_USERS,
                },
                {
                    path: RouteFor.admin.groups,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_GROUPS,
                },
                {
                    path: RouteFor.admin.permissions,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_PERMISSIONS,
                },
                {
                    path: RouteFor.admin.domains,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DOMAINS,
                },
                {
                    path: RouteFor.admin.signon,
                    title: translationKeys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SIGNON,
                },
            ],
        },
    ];

    if (hasOpportunitySpace) {
        tabGroups[1].items.push({
            path: RouteFor.admin.opportunities,
            title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_OPPS,
        });
    }

    tabGroups[1].items.splice(2, 0, {
        path: RouteFor.admin.currencies,
        title: translationKeys.VDLANG_ADMIN_SIDEBAR_DATA_CURRENCIES,
    });

    const heading = translate(translationKeys.VDLANG_SECTIONS_ADMIN);

    return (
        <SidebarLayout
            open={isMobileView ? isOpen : undefined}
            onOpen={() => (isMobileView ? setIsOpen(true) : undefined)}
            sidebar={
                <SidebarPreferences
                    header={
                        <List disablePadding={true}>
                            <SidebarTitleItem>
                                {heading}
                                {isMobileView ? (
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end">
                                            <CloseRounded onClick={() => setIsOpen(false)} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                ) : null}
                            </SidebarTitleItem>
                        </List>
                    }
                >
                    {tabGroups.map(({ title: groupTitle, items }) => [
                        <SidebarNavItemGroup key={groupTitle}>{translate(groupTitle)}</SidebarNavItemGroup>,
                        items.map(({ path, title }) => (
                            <SidebarNavItemButton
                                component={Link}
                                onClick={() => (isMobileView ? setIsOpen(false) : undefined)}
                                key={title}
                                selected={path === location.pathname}
                                to={path}
                            >
                                {translate(title)}
                            </SidebarNavItemButton>
                        )),
                    ])}
                </SidebarPreferences>
            }
            collapsedHeader={translate(translationKeys.VDLANG_SECTIONS_ADMIN)}
        >
            <Root>
                <RootRoutes>
                    <Route path={RouteFor.admin.view} element={<Navigate replace to={RouteFor.admin.organization} />} />
                    <Route path={RouteFor.admin.organization} element={<TeamSetting />} />
                    <Route path={RouteFor.admin.companies} element={<CompaniesSettings />} />
                    <Route path={RouteFor.admin.treeNodes} element={<TreeNodeSettings />} />
                    <Route path={RouteFor.admin.suppliers} element={<SupplierManagement />} />
                    <Route path={RouteFor.admin.users} element={<MembersSettings />} />
                    <Route path={RouteFor.admin.groups} element={<GroupSettings />} />
                    <Route path={RouteFor.admin.permissions} element={<PermissionSettings />} />
                    <Route path={RouteFor.admin.measures} element={<MeasuresSettings />} />
                    {hasOpportunitySpace && <Route path={RouteFor.admin.opportunities} element={<OppsSettings />} />}
                    <Route
                        path={RouteFor.admin.domains}
                        element={
                            <DomainsSettings
                                translate={translate}
                                domains={domainsQuery.data}
                                identityProviders={identityProvidersQuery.data ?? []}
                            />
                        }
                    />
                    <Route
                        path={RouteFor.admin.signon}
                        element={
                            <SignOnSettings
                                translate={translate}
                                identityProviders={identityProvidersQuery.data ?? []}
                                domains={domainsQuery.data}
                                users={usersQuery.data}
                            />
                        }
                    />
                    <Route path={RouteFor.admin.currencies} element={<CurrencySettings />} />
                    <Route path={RouteFor.admin.departments} element={<DepartmentsSettings />} />
                </RootRoutes>
            </Root>
        </SidebarLayout>
    );
};

export default React.memo(AdminView);
