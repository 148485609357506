import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    AclRuleStarID,
    AdminMeasureConfigListDto,
    CreateAclsRequestBody,
    GroupDto,
    UserDto,
} from "api-shared";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";

import { TFunction } from "i18next";
import { z } from "zod";
import Select from "../../../components/input/select/Select";
import { translationKeys } from "../../../translations/main-translations";
import { DialogForm } from "./DialogForm";
import { GroupSelect } from "./Inputs/GroupSelect";
import { UserSelect } from "./Inputs/UserSelect";
import { zFormUserGroupSchema } from "./PermissionsConstants";

type FormValues = Pick<CreateAclsRequestBody, "userIds" | "groupIds"> & { measureConfigId: number };

type ValuestreamDialogProps = {
    open?: boolean;
    onClose?: () => void;
    measureConfigs: AdminMeasureConfigListDto;
    users: UserDto[];
    groups: GroupDto[];
    onPermissionSubmit: (data: CreateAclsRequestBody) => void;
};

const getMeasureConfigOptions = (t: TFunction, measureConfigs?: AdminMeasureConfigListDto) => {
    if (measureConfigs === undefined) {
        return [];
    } else {
        return [
            { value: 0, label: t(translationKeys.VDLANG_ALL) },
            ...measureConfigs
                .map(({ id, name }) => ({
                    value: id,
                    label: t(name),
                }))
                .toSorted((a, b) => a.label.localeCompare(b.label)),
        ];
    }
};

export const ValuestreamDialog = ({
    open = false,
    onClose = () => {},
    measureConfigs,
    users,
    groups,
    onPermissionSubmit,
}: ValuestreamDialogProps) => {
    const { t } = useTranslation();

    const measureConfigOptions = getMeasureConfigOptions(t, measureConfigs);

    const {
        handleSubmit,
        control,
        formState: { isValid },
        reset,
    } = useForm<FormValues>({
        mode: "onChange",
        resolver: zodResolver(zFormUserGroupSchema.and(z.object({ measureConfigId: z.number().int().min(0) }))),
        defaultValues: { userIds: [], groupIds: [], measureConfigId: 0 },
    });

    const onSubmit = handleSubmit((data) => {
        onPermissionSubmit({
            groupIds: data.groupIds,
            userIds: data.userIds,
            acl: {
                namespace: AclNamespaces.Valuestream,
                permission: AclPermissions.Read,
                rule: data.measureConfigId === 0 ? AclRuleStarID : [{ fact: "id", operator: "equal", value: data.measureConfigId }],
                simpleEntityId: data.measureConfigId === 0 ? null : data.measureConfigId,
            },
        });
        onClose();
    });

    const onCancel = () => {
        onClose();
        reset();
    };

    return (
        <ActionItemDialog
            open={open}
            title={`${t(translationKeys.VDLANG_ACL_ADD_RULE)} - ${t(translationKeys.VDLANG_ACL_TITLE_VALUESTREAM)}`}
            primary={t("Create")}
            primaryIsTranslated
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            onClose={onCancel}
            translate={t}
            disableContentPadding
            maxWidth="sm"
        >
            <DialogForm onSubmit={onSubmit} style={{ margin: 0 }}>
                <Stack gap={1}>
                    <Controller
                        name="measureConfigId"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return (
                                <Select
                                    margin="none"
                                    label={t(translationKeys.VDLANG_ACL_TITLE_VALUESTREAM)}
                                    value={measureConfigOptions.find((t) => t.value === value)}
                                    options={measureConfigOptions}
                                    onChange={(o) => onChange(o?.value ?? 0)}
                                    menuPortalTarget={document.body}
                                    isSearchable={true}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="groupIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <GroupSelect onChange={onChange} value={value} groups={groups} />;
                        }}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={({ field }) => {
                            const { value, onChange } = field;
                            return <UserSelect value={value} onChange={onChange} users={users} />;
                        }}
                    />
                </Stack>
            </DialogForm>
        </ActionItemDialog>
    );
};
